import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { BuddyMessage, RequestMessage, ResponseMessage } from './buddy-data-structures/buddy-message';
import { ModalController, ToastController } from '@ionic/angular';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { BuddyMessageFeedback } from './buddy-data-structures/buddy-message-feedback';
import { BuddyMessageFeedbackReaction } from './buddy-data-structures/buddy-message-feedback-reaction';
import { BuddyDislikeFeedbackComponent } from './buddy-dislike-feedback/buddy-dislike-feedback.component';
import { BuddyMessageFeedbackFlag } from './buddy-data-structures/buddy-message-feedback-flag';
import { BuddyFeedbackConfirmationComponent } from './buddy-confirmation/buddy-feedback-confirmation.component';
import { BuddyErrorModalService } from './buddy-services/buddy-error-modal.service';
import { BUDDY_AUTH_SERVICE_TOKEN } from './buddy-services/buddy-auth-service-token';
import { BuddyConfig } from './buddy-data-structures/buddy-config';
import { BuddyApiService } from './buddy-services/buddy-api.service';

@Component({
    selector: 'buddy',
    templateUrl: 'buddy.component.html',
    styleUrls: ['./buddy.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class BuddyComponent implements OnDestroy, OnInit, AfterViewInit {
    @Input() iconSource: string = '';
    @Input() title: string;
    @Input() footerText: string;
    @Input() greetingMessage: string;
    @Input() buddyConfig: BuddyConfig;
    @Input() showHeader: boolean = true;

    chatId: string;
    isSending = false;
    isWaiting = true;
    shouldContinueParseResponse = true;
    question = '';
    @ViewChild('message', { static: false }) textarea: ElementRef;
    showPulsingDots$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    messages$: BehaviorSubject<BuddyMessage[]> = new BehaviorSubject<BuddyMessage[]>(this.messages);
    suggestedQuestions$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(this.suggestedQuestions);

    constructor(
        private readonly buddyApiService: BuddyApiService,
        private readonly modalCtrl: ModalController,
        private readonly errorModalService: BuddyErrorModalService,
        private readonly toastController: ToastController,
        @Inject(BUDDY_AUTH_SERVICE_TOKEN) private readonly authService: AuthService,
    ) {}

    private _messages: BuddyMessage[] = [];
    private _suggestedQuestions: string[] = [];

    get messages(): BuddyMessage[] {
        return this._messages;
    }

    get suggestedQuestions(): string[] {
        return this._suggestedQuestions;
    }

    async ngOnInit() {
        await this.createChat();
        this.textarea.nativeElement.addEventListener('input', () => this.resizeTextarea());
        if (this.greetingMessage) {
            this.addMessage({ text: this.greetingMessage, messageType: 'response' });
        }
        if (this.buddyConfig?.initialPrompt) {
            this.sendToAI(this.buddyConfig?.initialPrompt);
        }
    }

    ngAfterViewInit() {
        this.textarea.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
            if (!this.isSending && event.ctrlKey && event.key === 'Enter') {
                this.generateResponse();
            }
        });
    }

    ngOnDestroy() {
        this.stopResponse();
    }

    async generateResponse() {
        this.shouldContinueParseResponse = false;
        if (this.question.trim() === '') {
            this.resetQuestionTextArea();
            return;
        }
        this.isWaiting = true;
        this.isSending = true;
        this.shouldContinueParseResponse = true;
        this.textarea.nativeElement.disabled = true;
        this.writeSuggestedQuestions([]);
        await this.sendToAI();
        this.textarea.nativeElement.disabled = false;
        this.isSending = false;
    }

    stopResponse() {
        this.isSending = false;
        this.shouldContinueParseResponse = false;
    }

    async sendToAI(userInput = this.question) {
        let requestMessageIndex: number;
        try {
            const requestMessage = new RequestMessage(this.question);
            requestMessageIndex = this.addMessage(requestMessage);
            this.showPulsingDots$.next(true);
            const messagePayload = this.buddyConfig?.payloadExtension
                ? { prompt: userInput, ...this.buddyConfig.payloadExtension }
                : { prompt: userInput };
            const response = await fetch(`${environment.CHATBOT_BACKEND}/connected/chats/${this.chatId}/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ` + (await firstValueFrom(this.authService.session$)).tokenSet.access_token,
                },
                body: JSON.stringify(messagePayload),
            });
            if (!response.ok) {
                console.error('Network response was not ok. Response:', response);
                this.showMsgSendFailedModal(response.status + '', await response.text());
                this.undoAddMessage(requestMessageIndex);
            } else {
                this.isWaiting = false;
                this.resetQuestionTextArea();
                await this.parseResponse(response);
            }
        } catch (error) {
            console.error('There has been a problem:', error);
            this.showMsgSendFailedModal(error?.status + '' || 'unknown', error?.message || 'unknown');
            this.undoAddMessage(requestMessageIndex);
        }
    }

    private async parseResponse(response: Response) {
        if (typeof response?.body?.getReader === 'function') {
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');

            let isBeginOfMessage = true;

            while (this.shouldContinueParseResponse) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunks = decoder.decode(value, { stream: true }).split('\n');

                for (let chunk of chunks) {
                    this.processChunk(chunk, isBeginOfMessage);
                    isBeginOfMessage = false;
                }
            }
        }
    }

    private processChunk(chunk: string, isBeginOfMessage: boolean) {
        if (chunk.startsWith('data: ') && this.shouldContinueParseResponse) {
            const data = JSON.parse(chunk.substring(6));
            if (isBeginOfMessage) {
                this.showPulsingDots$.next(false);
                this.addMessage(new ResponseMessage(data.content, data.message_id, new BuddyMessageFeedback()));
            } else {
                const answerMessage = this.findMessageById(data.message_id);
                answerMessage.text += data.content;
                this.updateMessage(answerMessage);
            }
            if (data.stop) {
                this.isSending = false;
                this.textarea.nativeElement.disabled = false;
            }
            if (data.suggested_questions) {
                this.writeSuggestedQuestions(data.suggested_questions);
            }
        }
    }

    private undoAddMessage(messageIndex: number) {
        this.removeMessageByIndex(messageIndex);
        this.showPulsingDots$.next(false);
        this.isWaiting = false;
    }

    private resetQuestionTextArea() {
        this.question = '';
        this.textarea.nativeElement.rows = 1;
        this.textarea.nativeElement.style.height = 'auto';
    }

    async toggleLikeFeedback(message: ResponseMessage) {
        if (message.messageType !== 'response' || message?.messageId === undefined) return;

        const originalReaction = message.feedback.reaction;
        const updatedReaction: BuddyMessageFeedbackReaction =
            originalReaction === BuddyMessageFeedbackReaction.LIKE
                ? BuddyMessageFeedbackReaction.NONE
                : BuddyMessageFeedbackReaction.LIKE;

        await this.sendFeedback(message, { reaction: updatedReaction });
    }

    async toggleDislikeFeedback(message: ResponseMessage) {
        if (message.messageType !== 'response' || message?.messageId === undefined) return;

        const originalReaction = message.feedback.reaction;
        const updatedReaction: BuddyMessageFeedbackReaction =
            originalReaction === BuddyMessageFeedbackReaction.DISLIKE
                ? BuddyMessageFeedbackReaction.NONE
                : BuddyMessageFeedbackReaction.DISLIKE;

        if (updatedReaction === BuddyMessageFeedbackReaction.NONE) {
            await this.sendFeedback(message, { reaction: updatedReaction });
        } else {
            const modal = await this.modalCtrl.create({
                component: BuddyDislikeFeedbackComponent,
            });
            await modal.present();
            const dismissal = await modal.onDidDismiss<BuddyMessageFeedbackFlag>();
            if (dismissal.role === 'confirm' && dismissal.data) {
                const dislikeFeedback: BuddyMessageFeedback = { reaction: updatedReaction, flag: dismissal.data };
                const feedbackDislikeSuccess = await this.sendFeedback(message, dislikeFeedback);
                if (feedbackDislikeSuccess) {
                    message.feedback.reaction = updatedReaction;
                    this.messages$.next(this._messages);
                    const chatbotFeedbackConfirmationModal = await this.modalCtrl.create({
                        component: BuddyFeedbackConfirmationComponent,
                        cssClass: 'mini-modal-bottom',
                    });
                    await chatbotFeedbackConfirmationModal.present();
                }
            }
        }
    }

    /**
     * Sends feedback to the chatbot backend. Optimistically applies feedback locally that might be reverted if sending fails.
     * @param message the message to send feedback for (will be updated with feedback).
     * @param feedback the feedback to send and apply locally.
     */
    async sendFeedback(message: ResponseMessage, feedback: BuddyMessageFeedback): Promise<boolean> {
        try {
            if (
                feedback.reaction === BuddyMessageFeedbackReaction.NONE ||
                feedback.reaction === BuddyMessageFeedbackReaction.LIKE
            ) {
                feedback.flag = null;
                feedback.comment = null;
            }
            const originalFeedback = { ...message.feedback };
            // optimistically apply feedback locally, might be reverted if sending fails
            message.feedback = feedback;
            this.messages$.next(this._messages);
            const feedbackEndpoint = `${environment.CHATBOT_BACKEND}/chats/${this.chatId}/messages/${message.messageId}/feedback`;
            const feedbackResponse = await fetch(feedbackEndpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(feedback),
            });
            const feedbackSuccess = await feedbackResponse.json();
            if (!feedbackSuccess || feedbackResponse.status === 500) {
                // revert feedback locally
                await this.presentToast($localize`:@@pages.chatbot.send-feedback-failed:Missing translation`);
                message.feedback = originalFeedback;
                this.updateMessage(message);
                return false;
            }
            return feedbackSuccess;
        } catch (e) {
            console.error(
                `Failed to send Feedback (Chat ID: ${this.chatId}, Message ID: ${message.messageId}):`,
                feedback,
            );
        }
    }

    private addMessage(message: BuddyMessage) {
        this._messages.push(message);
        this.messages$.next(this._messages);
        return this._messages.length - 1;
    }

    async createChat() {
        const showCreateChatFailedModal = async (error: Error) => {
            await this.errorModalService.showErrorDialog({
                error: error,
                displayMessage: $localize`:@@pages.chatbot.create-chat-failed:Missing translation`,
                escapeAction: 'back',
                customRetryButton: {
                    label: $localize`:@@pages.chatbot.retry:Missing translation`,
                },
            });
        };

        try {
            const accessToken = (await firstValueFrom(this.authService.session$))?.tokenSet?.access_token;
            const chatCreatedResponse = await this.buddyApiService.createChat(accessToken);
            this.chatId = chatCreatedResponse?.chatId;
            if (!this.chatId) {
                const message = `Error creating chat: ChatId is undefined`;
                console.error(message);
                await showCreateChatFailedModal(new Error(message));
            }
        } catch (e) {
            console.error(`Error creating chat: `, e);
            await showCreateChatFailedModal(e);
        }
    }

    private resizeTextarea() {
        //height needs to set to auto first, otherwise textarea won't shrink with deleting lines
        this.textarea.nativeElement.style.height = 'auto';
        const scrollHeight = this.textarea.nativeElement.scrollHeight;
        const maxHeightPx = 125;
        const newHeightPx = Math.min(scrollHeight, maxHeightPx);
        this.textarea.nativeElement.style.height = newHeightPx + 'px';

        //scroll to bottom to keep new lines on same place
        const cursorAtEnd = this.textarea.nativeElement.selectionEnd === this.textarea.nativeElement.value.length;
        if (cursorAtEnd) {
            this.textarea.nativeElement.scrollTop = this.textarea.nativeElement.scrollHeight;
        }
    }

    private showMsgSendFailedModal(status: string, message: string) {
        // noinspection JSIgnoredPromiseFromCall
        this.errorModalService.showErrorDialog({
            showRetryBtn: true,
            allowDismiss: false,
            escapeAction: 'dismiss',
            displayMessage: $localize`:@@pages.chatbot.send-msg-failed:Missing translation`,
            error: new Error(`Failed to send message (status: ${status}). message: ${message}`),
            customRetryButton: {
                label: $localize`:@@pages.chatbot.retry:Missing translation`,
                retryAction: () => {
                    this.generateResponse();
                },
            },
        });
    }

    private removeMessageByIndex(messageIndex: number) {
        this._messages.splice(messageIndex, 1);
        this.messages$.next(this._messages);
    }

    private updateMessage(message: ResponseMessage) {
        const index = this._messages.findIndex((m) => (m as ResponseMessage).messageId === message.messageId);
        if (index !== -1 && message.messageId != undefined) {
            this._messages[index] = message;
        } else {
            throw new Error(`Message with id ${message.messageId} not found`);
        }
        this.messages$.next(this._messages);
    }

    private findMessageById(messageId: string): ResponseMessage | undefined {
        return this._messages.find((m) => (m as ResponseMessage).messageId === messageId) as ResponseMessage;
    }

    private writeSuggestedQuestions(suggestedQuestions: string[]) {
        this._suggestedQuestions = suggestedQuestions;
        this.suggestedQuestions$.next(this._suggestedQuestions);
    }

    sendSuggestionAsQuestion(suggestion: string) {
        this.question = suggestion;
        this.generateResponse();
    }

    private async presentToast(message: string) {
        const toast = await this.toastController.create({
            message,
            duration: 2000,
            position: 'top',
        });
        await toast.present();
    }
}
