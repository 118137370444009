import { ChatCreatedResponseInterface } from '../buddy-data-structures/chat-created-response.interface';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class BuddyApiService {
    constructor(private readonly http: HttpClient) {}

    /**
     * Creates a new chat.
     * @param accessToken an access token to use for the request. The request will be authenticated and the chat will be assigned to the specified user.
     * @returns a promise that resolves to the chat id of the newly created chat.
     */
    async createChat(accessToken: string): Promise<ChatCreatedResponseInterface> {
        return firstValueFrom(
            this.http.post<ChatCreatedResponseInterface>(
                `${environment.CHATBOT_BACKEND}/connected/chats`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    withCredentials: true,
                },
            ),
        );
    }
}
