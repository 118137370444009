import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { BuddyDislikeFeedbackComponent } from './buddy-dislike-feedback/buddy-dislike-feedback.component';
import { BuddyFeedbackConfirmationComponent } from './buddy-confirmation/buddy-feedback-confirmation.component';
import { BuddyMessagesComponent } from './buddy-messages/buddy-messages.component';
import { BuddyHistoryComponent } from './buddy-history/buddy-history.component';
import { MarkdownModule } from 'src/app/markdown/markdown.module';
import { BuddyComponent } from './buddy.component';
import { BUDDY_AUTH_SERVICE_TOKEN } from './buddy-services/buddy-auth-service-token';
import { BuddyApiService } from './buddy-services/buddy-api.service';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule, HttpClientModule, MarkdownModule],
    declarations: [
        BuddyDislikeFeedbackComponent,
        BuddyFeedbackConfirmationComponent,
        BuddyMessagesComponent,
        BuddyHistoryComponent,
        BuddyComponent,
    ],
    exports: [BuddyHistoryComponent, BuddyComponent],
})
export class BuddyModule {
    static forRoot(authServiceClass: Type<any>): ModuleWithProviders<BuddyModule> {
        return {
            ngModule: BuddyModule,
            providers: [{ provide: BUDDY_AUTH_SERVICE_TOKEN, useExisting: authServiceClass }, BuddyApiService],
        };
    }
}
