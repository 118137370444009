import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DwinUiCategory } from '../common/entities/dwin-ui-category';
import { User } from '../auth/user';
import { RouterExtension } from '../router/router.service';
import { AuthService } from '../auth/auth.service';
import packageJson from 'package.json';
import { RequiredActionsService } from '../dwin/services/required-actions/required-actions.service';
import { RequiredAction } from '../graphql-generated';

interface DwinSideMenuButton {
    iconPath?: string;
    label: string;
    url: string;
    category: DwinUiCategory;
}

@Component({
    selector: 'dwin-side-menu',
    templateUrl: './dwin-side-menu.component.html',
    styleUrls: ['./dwin-side-menu.component.scss'],
})
export class DwinSideMenuComponent implements OnInit, OnDestroy {
    profileButton: DwinSideMenuButton = {
        iconPath: 'assets/imgs/icon-profile.svg',
        label: $localize`:@@general.account:Missing translation`,
        url: '/profile',
        category: DwinUiCategory.PROFILE,
    };
    feedbackButton: DwinSideMenuButton = {
        iconPath: 'assets/imgs/icon-feedback.svg',
        label: $localize`:@@components.side-menu.feedback-and-support:Missing translation`,
        url: '',
        category: DwinUiCategory.FEEDBACK,
    };

    sideMenuLegalButtons: DwinSideMenuButton[] = [];

    loggedInUserSubscription: Subscription;
    loggedInUser: User;
    appVersion: string;

    private reqActionsSub: Subscription;
    private requiredActions: RequiredAction[] = [];

    displayLegalButtons = false;

    constructor(
        private readonly router: RouterExtension,
        public readonly menuController: MenuController,
        private readonly authService: AuthService,
        private readonly reqActionsService: RequiredActionsService,
    ) {
        this.appVersion = packageJson.version;
    }

    ngOnInit() {
        this.sideMenuLegalButtons = [
            {
                label: $localize`:@@components.side-menu.privacy-policy:Missing translation`,
                category: DwinUiCategory.PRIVACY,
                url: 'datenschutzerklaerung-dwin-app',
            },
            {
                label: $localize`:@@components.side-menu.terms-of-use:Missing translation`,
                category: DwinUiCategory.USER_AGREEMENT,
                url: 'nutzungsbedingungen-dwin-app',
            },
            {
                label: $localize`:@@components.side-menu.imprint:Missing translation`,
                category: DwinUiCategory.IMPRINT,
                url: 'impressum',
            },
        ];
        this.loggedInUserSubscription = this.authService.loggedInUser.subscribe((user) => {
            this.loggedInUser = user;
            if (this.loggedInUser) this.reqActionsService.checkForRequiredActions();
        });
        this.reqActionsSub = this.reqActionsService.requiredActionChanges$.subscribe((ra) => {
            this.requiredActions = ra;
        });
    }

    ngOnDestroy() {
        this.loggedInUserSubscription.unsubscribe();
        this.reqActionsSub.unsubscribe();
    }

    async navigateToPage(url: string) {
        await this.initUser();
        await this.router.navigate([url]);
        await this.menuController.close();
    }

    openDwinHealthPage(url: string) {
        window.open('https://www.dwin.health/' + url, '_blank');
    }

    async login() {
        await this.authService.authorizationCodeAuth();
    }

    async logout() {
        await this.authService.logout('');
        this.menuController.close();
    }

    private async initUser() {
        const session = this.authService.getSession();
        this.loggedInUser = session ? await this.authService.getSession().user : null;
    }

    toggleLegalButtons() {
        this.displayLegalButtons = !this.displayLegalButtons;
    }
}
